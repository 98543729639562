<template>
    <b-row>
        <div class="col px-1">
            <div class="row px-1 mb-3">
                <div class="col-12" style="padding: 20px; background-color: #283046; border-radius: 5px; height: 322px;">
                    <div>
                        <div
                            v-if="live !== null"
                        >
                            <!-- <div
                            v-if="liveSpeaker.password_vmix"
                            class="embed-responsive embed-responsive-16by9"
                            >
                            <div v-if="!(sameDevice)">
                                Conectando em {{ 10 - timer }} ...
                            </div>
                            <iframe
                                :src="`https://advanced.vmixcall.com/call.htm?Key=${liveSpeaker.password_vmix}&Name=${liveSpeaker.speaker.name_badge}`"
                                width="100%"
                                height="280"
                                allow="camera;microphone;fullscreen"
                                style="border:none;"
                                allowfullscreen=""
                            />
                            </div> -->
                            <div style="height: 280px;">
                            <iframe
                                v-if="live.streaming === 'cloudflare' || live.streaming === 'Cloudflare'"
                                id="cloudflare"
                                :src="vimeo_base + live.link + '?preload=true&loop=true&autoplay=true'"
                                style="left: 0px; width: 100%; border: 0"
                                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                                allowfullscreen="true"
                                width="100%"
                                height="100%"
                                loop="true"
                            />

                            <vue-plyr
                                v-if="live.streaming === 'youtube' || live.streaming === 'Youtube'"
                                :options="options"
                                style="height: 100%;"
                            >
                                <div
                                data-plyr-provider="youtube"
                                :data-plyr-embed-id="live.link"
                                allow="autoplay"
                                height="100%"
                                />
                            </vue-plyr>

                            <iframe
                                v-if="live.streaming === 'vimeo' || live.streaming === 'Vimeo'"
                                :src="'https://player.vimeo.com/video/'+live.link+'?autoplay=true&loop=false&byline=false&portrait=false&title=false&speed=true'"
                                frameborder="0"
                                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture; fullscreen;"
                                allowfullscreen
                                style="overflow:hidden; width:100%"
                                width="100%"
                                height="100%"
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-between col-lg-12 col-md-6" style="height: 211px; margin: 0; padding: 0;">
                <div class="col-lg-5 col-md-6 px-0">
                    <div class="row px-1 align-items-baseline" style="height: 25px;">
                        <h5 style="margin-right: 5px;">Título:</h5>
                        <p>{{ live.title }}</p>
                    </div>
                    <div class="row px-1 align-items-baseline" style="height: 25px;">
                        <h5 style="margin-right: 5px;">Assunto:</h5>
                        <p>-</p>
                    </div>
                    <div class="row px-1 align-items-baseline" style="height: 25px;">
                        <h5 style="margin-right: 5px;">Data da palestra:</h5>
                        <p>{{ date }}</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6" style="padding: 0;">
                    <chat
                    :live-id="live.id"
                    :user-id="sessionInfo.user.id"
                    style="margin:0px;z-index: 999999; height: 100%; width: 100%;"
                    />
                </div>
            </div>
        </div>
        <div v-if="lives.length > 1" class="col-2" style="border: 1px solid #404656; border-radius: 5px; height: 90%; padding: 10px;">
            <div v-for="live in lives" :key="live.id">
                <b-link @click="tryToGetIn(live.id)">
                    <img
                        style=" object-fit: cover;  height: 200px;"
                        class="rounded w-100  border border-primary "
                        :src="BaseUrl + '/storage/images/event_live_banner/' + live.logo"
                        :alt="live.title"
                    />
                    <h5>{{ live.title }}</h5>
                </b-link>
            </div>
        </div>
    </b-row>
</template>

<script>
import axios from "axios";
import chat from '@/components/chat/ChatLive.vue'
import EventsServices from './eventsServices'
export default {
  components: {
    chat
  },
  setup() {
  },
  data() {
    return {
      youtube_base: 'https://www.youtube.com/embed/',
      vimeo_base: 'https://iframe.videodelivery.net/',
      lives: [],
      BaseUrl: process.env.VUE_APP_API_BASE,
      searchValue: null,
      frameLive: false,
      liveSpeaker: {},
      live: {},
      date: ''
    };
  },
  computed: {
    sessionInfo() {
      return this.$store.state.auth.sessionInfo
    },
  },
  beforeMount() {
    this.fetchLives();
  },
  created() {
    
  },
  async mounted() {
    await EventsServices().getLive(this.$route.params.id).then(api => {
      this.live = api.data.response
    })

    setInterval(() => {
      EventsServices().getLive(this.$route.params.id).then(api => {
        this.live = api.data.response
      })
    }, 30000)

    let listDate = this.live.created_at.split('-')
    let months = ['janeiro', 'fevereiro', 'março','abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro']
    this.date = `${listDate[2][0]}${listDate[2][1]} de ${months[parseInt(listDate[1])-1]} de ${listDate[0]}`
  },
  watch: {
  },
  methods: {
    fetchLives() {
      this.frameLive = false;
      const apiUrl = process.env.VUE_APP_API + "/events/lives/get-lives/" + this.$route.params.id_old;
      axios
        .get(apiUrl)
        .then((response) => {
          this.lives = response.data.lives;
        })
        .catch((error) => {
          console.error("Error fetching lives:", error);
        });
    },
    tryToGetIn(live_id) {

      const axiosConfig = {
        headers: {
          'Authorization': 'Bearer ' + this.token,
        }
      };
      
      const apiUrl = process.env.VUE_APP_API + `/events/lives/get-lives/${live_id}`;

      let status_permission = null

      axios
      .get(apiUrl, axiosConfig)
      .then((response) => {
        this.lives = response.data.response;
        
        status_permission = response.data.status;
        
        if (status_permission === 200) {
          this.$router.push({ name: 'live_show', params: { id: live_id, id_old: this.$route.params.id_old }})
          window.location.reload();
        }
      })
      this.frameLive = true;
    },
  },
};
</script>

<style scoped>
.img-event {
  border-color: #161D31 !important;
  border-width: 2px !important;
  margin-bottom: 10px;
}

.link-none {
  text-decoration: none !important;
  color: inherit;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.bg-dark-blue {
  background-color: #161d31 !important;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
</style>